import bgImg from "../Assets/octopus.png"
import LoginIcon from '@mui/icons-material/Login';
import FacebookIcon from '@mui/icons-material/Facebook';

const Hero = () => {
  return (
      <section id="home" className="text-gray-600 body-font bg-gradient-to-b from-fuchsia-200 to-fuchsia-50 dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-700">
          <div className="container max-w-screen-xl flex px-5 py-10 md:flex-row flex-col items-center mx-auto">
              <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-6 md:mb-0 mt-24 select-none mx-auto">
                  <img data-aos="fade-down"  alt="octopus" src={bgImg}/>
              </div>
              <div className="flex flex-col md:items-start md:text-left items-center mt-10 sm:mt-24 text-center mx-24">
                  <h1 className="select-none title-font text-[48px] md:text-[38px] lg:text-[42px] xl:text-[39px] mb-4 font-pacifico text-gray-900 dark:text-white w-full">Vítejte u <span className="text-fuchsia-800 dark:text-fuchsia-400">Chobotničky</span></h1>

                  {/*<p  className="select-none mb-2 relative leading-relaxed text-justify dark:text-white">Vítáme Vás na stránkách Baby Clubu Chobotnička a pevně věříme, že si vyberete pro svou malou ratolest něco z naší <a href="#price" className="text-fuchsia-800 dark:text-fuchsia-500">nabídky programů</a>.</p>*/}

                  <div className="mt-4 flex flex-col lg:flex-row relative">
                      <a href="#loginPaper"><button type="button" className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300 select-none uppercase font-sans text-white bg-fuchsia-800 border border-fuchsia-800 focus:outline-none hover:bg-gray-100 hover:text-fuchsia-800 focus:ring-4 focus:ring-gray-200 font-normal rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-full"><LoginIcon/> Přihlášky k plavání</button></a>
                      <a href="http://facebook.com/plavaniprodeti"><button type="button" className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300 select-none focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 w-full lg:w-20 lg:ml-4"><FacebookIcon/></button></a>
                  </div>


              </div>
          </div>
      </section>
  )
}


export default Hero