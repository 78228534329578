import {Collapse, initTE, Carousel} from "tw-elements";
import {useEffect} from "react";
import obr1 from "../Assets/Carousel/p1.jpeg"
import obr2 from "../Assets/Carousel/p2.jpeg"
import obr3 from "../Assets/Carousel/p3.jpeg"

const About = () => {

        useEffect(() => {
            initTE({ Carousel, Collapse });
        }, []);

    return (
      <section id="about" className="mt-4 py-6 sm:py-8 lg:py-12 rounded-t-[0%]">
          <div className="mx-auto max-w-screen-xl px-4 md:px-4" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">
              <h1 className="mb-4 text-xl pt-20 text-center font-extrabold tracking-tight leading-tight text-gray-800 uppercase sm:text-3xl md:mb-6 dark:text-stone-50">O Chobotničce</h1>

              <p className="mb-6 text-gray-500 sm:text-lg font-light md:mb-8 text-justify dark:text-stone-200">
                  Baby club Chobotnička vznikl v roce 2003, kdy jsme zahájili plavání kojenců a později i plavání batolat. Do roku 2007 probíhaly také aktivity pro maminky a děti v DDM v Hlučíně. Od února 2009 jsme rozšířili aktivity Baby clubu v nových prostorách pro maminky a děti od kojeneckého až do předškolního věku. Od roku 2012 se  Baby club zaměřuje pouze na plavání kojenců ve vaničkách v domácím prostředí, plavání kojenců a batolat, plavání dětí předškolního a školního věku v bazénech.  <br/>
              </p>

              <h2 className="mb-2 mt-8 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 dark:text-stone-50">Co Vás nejčastěji zajímá?</h2>

              <div id="accordionExample" className="font-light">
                  <div className="rounded-t-lg border border-neutral-200 bg-white dark:bg-gray-900 dark:border-neutral-500">
                      <h2 className="mb-0" id="headingOne">
                          <button className="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Kde probíhají plavecké lekce?
                              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                  </svg>
                              </span>
                          </button>
                      </h2>
                      <div id="collapseOne" className="!visible" data-te-collapse-item data-te-collapse-show aria-labelledby="headingOne" data-te-parent="#accordionExample">
                          <div className="px-5 py-4 text-black dark:text-stone-50">
                              Plavání probíhá pro děti <b>od 6 měsíců</b> v areálu <a href="https://www.sareza.cz/vodni-svet/"><strong className="text-fuchsia-800 dark:text-fuchsia-500">Vodní svět Sareza Ostrava (ČAPKÁRNA)</strong></a>. Pro děti <b>od 1 roku</b> pak lekce probíhájí v bazénu <a href="https://www.hotel-landek.cz/wellness/"><strong className="text-fuchsia-800 dark:text-fuchsia-500">Apartmánového domu Landek v Petřkovicích</strong></a>.
                          </div>
                      </div>
                  </div>

                  <div className="border border-t-0 border-neutral-200 bg-white dark:bg-gray-900 dark:border-neutral-500">
                      <h2 className="mb-0" id="headingTwo">
                          <button className="group relative flex w-full items-center rounded-none border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Je možné se domluvit na individuálních lekcích nebo domácím plavání?
                              <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                  </svg>
                              </span>
                          </button>
                      </h2>
                      <div id="collapseTwo" className="!visible hidden" data-te-collapse-item aria-labelledby="headingTwo" data-te-parent="#accordionExample">
                          <div className="px-5 py-4 text-black dark:text-stone-50">
                              <strong>Ano.</strong> Po dohodě nabízíme tzv. <a href="/#"><strong className="text-fuchsia-800 dark:text-fuchsia-500">Vaničkové plavání</strong></a> pro děti <b>od 6 týdnů do 6 mesíců</b>. <a href="/#"><strong className="text-fuchsia-800 dark:text-fuchsia-500">Individuální lekce</strong></a> jsou také možné dle dohody na bazénech v Ostravě.
                          </div>
                      </div>
                  </div>

                  <div className="border border-t-0 border-neutral-200 bg-white dark:bg-gray-900 dark:border-neutral-500">
                      <h2 className="mb-0" id="headingTree">
                          <button className="group relative flex w-full items-center rounded-none border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target="#collapseTree" aria-expanded="false" aria-controls="collapseTree">Proč plavat u nás?
                              <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                  </svg>
                              </span>
                          </button>
                      </h2>
                      <div id="collapseTree" className="!visible hidden" data-te-collapse-item aria-labelledby="headingTree" data-te-parent="#accordionExample">
                          <div className="px-5 py-4">
                              <ul className="pl-10 list-disc text-black">
                                  <li>Jakákoli aktivita rodič + dítě má <b className="text-fuchsia-800 dark:text-fuchsia-500">nenahraditelný význam</b> pro vzájemný vztah i vývoj dítěte</li>
                                  <li>Výchova k <b className="text-fuchsia-800 dark:text-fuchsia-500">aktivnímu pohybu</b></li>
                                  <li><b className="text-fuchsia-800 dark:text-fuchsia-500">Rozvíjení</b> vůle a vytrvalosti</li>
                                  <li>Jsme <b className="text-fuchsia-800 dark:text-fuchsia-500">akreditovaní MŠMT ČR i ASOCIACÍ PLAVECKÝCH ŠKOL pro výuku plavání kojenců a batolat</b> . Akreditovaní pro plavání dětí předškolního a školního věku s dlouholetou praxí v Plavecké škole Ostrava i BC KENNY</li>
                                  <li>Patříme mezi kluby pracující s dětmi podle principů vhodného a ohleduplného zacházení s dítětem podporou <b className="text-fuchsia-800 dark:text-fuchsia-500">psychomotorického vývoje</b></li>
                                  <li><b className="text-fuchsia-800 dark:text-fuchsia-500">Dodržujeme zákon pro plavání s kojenci v bazénu</b> tzn. u nás až od 6 měsíců. (U dětí mladších 6 měsíců se při pobytu v bazénu zvyšuje riziko případných zdravotních potíží!!!)</li>
                                  <li>Pravidelně si <b className="text-fuchsia-800 dark:text-fuchsia-500">doplňujeme vzdělání</b> i poznatky nejen v oblasti speciální pedagogiky, proškolujeme svou odbornost a zajímáme se o aktuální dění v oblasti plavání s dětmi</li>
                              </ul>
                              </div>
                      </div>
                  </div>

                  <div className="rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:bg-gray-900 dark:border-neutral-500">
                      <h2 className="accordion-header mb-0" id="headingFour">
                          <button className="group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] [&[data-te-collapse-collapsed]]:rounded-b-[15px] [&[data-te-collapse-collapsed]]:transition-none" type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Jak plavání ovliňuje dítě ze zdravotního hlediska?
                              <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                              </svg>
                            </span>
                          </button>
                      </h2>
                      <div id="collapseFour" className="!visible hidden" data-te-collapse-item aria-labelledby="headingFour" data-te-parent="#accordionExample">
                          <div className="px-5 py-4">
                              <ul>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>otužování</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>příznivě  ovlivňuje srdečně-cévní systém i dýchací systém</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>zlepšuje se střevní peristaltika</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>v závislosti na plavání děti lépe prospívají, jí i spí</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>pohyb ve vodě napomáhá k odstranění různých nedostatků a vad držení těla</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>zdokonaluje kondici, vede ke správným a pravidelným pohybovým návykům</li>
                                  <li className='border-solid border-1 border-fuchsia-800 rounded-md bg-fuchsia-800 text-white uppercase text-center hover:text-white hover:bg-fuchsia-500 mb-2'>přispívá k  prevencí obezit</li>
                              </ul>

                          </div>
                      </div>
                  </div>

                  <div id="carouselExampleCaptions" className="relative pt-6 w-full" data-te-carousel-init data-te-carousel-slide>
                      <div className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0" data-te-carousel-indicators>
                          <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="0" data-te-carousel-active className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none" aria-current="true" aria-label="Slide 1"></button>
                          <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="1" className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none" aria-label="Slide 2"></button>
                          <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="2" className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none" aria-label="Slide 3"></button>
                      </div>
                      <div className="relative w-full overflow-hidden after:clear-both after:block after:content-[''] rounded">
                          <div className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-active data-te-carousel-item style={{backfaceVisibility: "hidden"}}>
                              <div className="relative overflow-hidden bg-cover bg-no-repeat" style={{backgroundPosition: "50%"}}>
                                  <img src={obr1} className="block w-full" alt=""/>
                                  <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-50"></div>
                              </div>
                              <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                  <h5 className="text-xl">Vaničkové plavání</h5>
                              </div>
                          </div>
                          <div className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-item style={{backfaceVisibility: "hidden"}}>
                              <div className="relative overflow-hidden bg-cover bg-no-repeat" style={{backgroundPosition: "50%"}}>
                                  <img src={obr2} className="block w-full" alt=""/>
                                  <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-50"></div>
                              </div>
                              <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                  <h5 className="text-xl">Plavání pro děti</h5>
                              </div>
                          </div>
                          <div className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-item style={{backfaceVisibility: "hidden"}}>
                              <div className="relative overflow-hidden bg-cover bg-no-repeat" style={{backgroundPosition: "50%"}}>
                                  <img src={obr3} className="block w-full" alt=""/>
                                  <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-50"></div>
                              </div>
                              <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                  <h5 className="text-xl">Plavání pro nejmenší</h5>
                              </div>
                          </div>
                      </div>
                      <button className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#carouselExampleCaptions" data-te-slide="prev">
                        <span className="inline-block h-8 w-8">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
                          </svg>
                        </span>
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
                      </button>
                      <button className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#carouselExampleCaptions" data-te-slide="next">
                        <span className="inline-block h-8 w-8">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
                          </svg>
                        </span>
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
                      </button>
                  </div>

              </div>
          </div>

      </section>
  )
}


export default About