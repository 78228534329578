import data from "../Components/Data/DB"

const Price = () => {

    let dataJSON = JSON.stringify(data)
    localStorage.setItem("data-table", dataJSON)
    let parseJSON = JSON.parse(dataJSON)

    const itemsTable = parseJSON.map((oneItem) => {

        const {id, name, desc, time, price, place} = oneItem

        return (
            <tr className="border-b border-gray-200 dark:border-gray-800" key={id}>
                <th scope="row"
                    className="px-6 py-4 uppercase font-bold text-fuchsia-800 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-900">
                    <a href="#loginPaper">{name}</a>
                </th>
                <td className="px-6 py-4 bg-gray-100 dark:bg-gray-900">
                    {desc}
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-900">
                    {place}
                </td>
                <td className="px-6 py-4 bg-gray-100 dark:bg-gray-900">
                    {time}
                </td>
                <td className="px-6 py-4 bg-gray-50 dark:bg-gray-900 font-bold dark:text-white">
                    {price}
                </td>
            </tr>
        )
    })

    const itemsMobileTable = parseJSON.map((oneItem) => {

        const {id, name, desc, time, price, place} = oneItem

        return (
            <a href="#loginPaper" key={id}><div className="bg-white p-4 rounded-lg shadow dark:bg-gray-800" >
                <div className="flex items-center space-x-2 text-sm pb-4">
                    <div className="text-fuchsia-800 font-bold uppercase dark:text-white">
                        {name}
                    </div>
                </div>
                <div className="h-0.5 w-full bg-fuchsia-800 rounded mb-4 dark:bg-fuchsia-500"></div>
                <div>
                    <div className="font-light text-justify mb-2 dark:text-stone-50">
                        {desc}
                    </div>
                    <div className="text-sm dark:text-stone-50">
                        Místo plavání: {place}
                    </div>
                    <div className="text-sm dark:text-stone-50">
                        Doba trvání: {time}
                    </div> <br/>
                    <div className="font-bold dark:text-stone-50">
                        {price}
                    </div>
                </div>

            </div></a>
        )
    })


  return (

    <section id="price" className="flex bg-gradient-to-b from-fuchsia-100 to-fuchsia-50 rounded-t-[10%] rounded-b-[10%] dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-700">
        <div className="container m-auto px-6 py-20 md:px-12 lg:px-20" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">
            <div className="m-auto text-center lg:w-8/12 xl:w-7/12">
                <h2 className="text-2xl text-pink-900 font-bold md:text-4xl pt-6 pb-8 dark:text-stone-50">Programy</h2>
            </div>


            <div className="relative overflow-x-auto shadow-md sm:rounded-lg hidden md:block">
                <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-900">
                            Kurzy
                        </th>
                        <th scope="col" className="px-6 py-3 bg-gray-100 dark:bg-gray-900">
                            Popis kurzu
                        </th>
                        <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-900">
                            Místo
                        </th>
                        <th scope="col" className="px-6 py-3 bg-gray-100 dark:bg-gray-900">
                            Doba trvání
                        </th>
                        <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-900">
                            Cena
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {itemsTable}
                    </tbody>
                </table>
            </div>

            <div className="grid grid-col-1 gap-4 md:hidden">
                {itemsMobileTable}
            </div>

            <div className="m-auto text-center lg:w-8/12 xl:w-7/12">
                <h2 className="text-xl text-pink-900 font-bold md:text-2xl pb-4 pt-20 dark:text-stone-50">Rozvrhy kurzů</h2>
            </div>

            <p id="schedule" className="mb-6 text-gray-500 sm:text-lg font-light md:mb-8 text-justify dark:text-stone-200">
                Individální lekce a Vaničkové plavání pouze po dohodě. Plavání kojenců, batolat a dětí předškolního a
                školního věku <b>dle rozvrhu</b> v bázénku. <b>Plavání pro MŠ od 9:30 - 10:30 na AD LANDEK</b>. <br/>
                <span className="text-fuchsia-800">**</span> V případě velkého zájmu plaveme ve čtvrtek na AD Landek dle rozvrhu <b>od 8:30 pro děti od 1 roku</b>. Jinak je plavání až od <b>9:00 do 9:30 pro děti od 1 do 2,5 let</b>
            </p>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 hidden md:block">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 uppercase font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            PO
                        </th>
                        <td className="px-6 py-4">
                            08:00 - 13:00
                        </td>
                        <td className="px-6 py-4 uppercase">
                            <a href="https://www.sareza.cz/vodni-svet/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">Vodní
                                svět</a>
                        </td>
                        <td className="px-6 py-4">
                            16:00 - 18:30
                        </td>
                        <td className="px-6 py-4">
                            <a href="https://www.hotel-landek.cz/wellness/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD
                                LANDEK</a>
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 uppercase font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            ST
                        </th>
                        <td className="px-6 py-4">
                            08:00 - 13:00
                        </td>
                        <td className="px-6 py-4 uppercase">
                            <a href="https://www.sareza.cz/vodni-svet/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">Vodní
                                svět</a>
                        </td>
                        <td className="px-6 py-4">
                            16:00 - 18:30
                        </td>
                        <td className="px-6 py-4">
                            <a href="https://www.hotel-landek.cz/wellness/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD
                                LANDEK</a>
                        </td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 uppercase font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            ČT <span className="text-fuchsia-800">**</span>
                        </th>
                        <td className="px-6 py-4">
                            08:30 - 09:30
                        </td>
                        <td className="px-6 py-4 uppercase">
                            <a href="https://www.hotel-landek.cz/wellness/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD
                                LANDEK</a></td>
                        <td className="px-6 py-4">
                            10:30 - 11:30
                        </td>
                        <td className="px-6 py-4">
                            <a href="https://www.hotel-landek.cz/wellness/"
                               className="uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD
                                LANDEK</a>
                        </td>

                    </tr>

                    </tbody>
                </table>
            </div>

            <div className="grid grid-col-1 gap-4 md:hidden">
                <div className="bg-white p-4 rounded-lg shadow dark:bg-gray-800">
                    <div className="flex items-center space-x-2 text-sm pb-2">
                        <div className="text-fuchsia-600 font-bold uppercase dark:text-white">
                            Pondělí
                        </div>
                    </div>
                    <div className="h-0.5 w-full bg-fuchsia-800 rounded mb-4 dark:bg-fuchsia-500"></div>
                    <div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                        <p>08:00 - 13:00<span className="ml-4 border-r-2"/><a href="https://www.sareza.cz/vodni-svet/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">Vodní svět</a></p>
                        </div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                            <p>16:00 - 18:00<span className="ml-4 border-r-2"/><a href="https://www.hotel-landek.cz/wellness/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD LANDEK</a></p>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2 text-sm pt-4 pb-2">
                        <div className="text-fuchsia-600 font-bold uppercase dark:text-white">
                            Středa
                        </div>
                    </div>
                    <div className="h-0.5 w-full bg-fuchsia-800 rounded mb-4 dark:bg-fuchsia-500"></div>
                    <div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                            <p>08:00 - 13:00<span className="ml-4 border-r-2"/><a href="https://www.sareza.cz/vodni-svet/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">Vodní svět</a></p>
                        </div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                            <p>16:00 - 18:00<span className="ml-4 border-r-2"/><a href="https://www.hotel-landek.cz/wellness/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD LANDEK</a></p>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2 text-sm pt-4 pb-2">
                        <div className="text-fuchsia-600 font-bold uppercase dark:text-white">
                            Čtvrtek **
                        </div>
                    </div>
                    <div className="h-0.5 w-full bg-fuchsia-800 rounded mb-4 dark:bg-fuchsia-500"></div>
                    <div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                            <p>08:30 - 09:30<span className="ml-4 border-r-2"/><a href="https://www.hotel-landek.cz/wellness/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD LANDEK</a></p>
                        </div>
                        <div className="font-light text-justify mb-2 dark:text-stone-50">
                            <p>10:30 - 11:30<span className="ml-4 border-r-2"/><a href="https://www.hotel-landek.cz/wellness/" className="ml-7 uppercase font-medium text-purple-900 dark:text-fuchsia-500 hover:underline">AD LANDEK</a></p>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </section>

  )
}


export default Price