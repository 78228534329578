import bgImg from "../Assets/octopus.png"
import Pdf from '../Assets/provozniRad.pdf';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Footer = () => {

   const currentYear = () => {
       return new Date().getFullYear()
   }
  return (
      <footer id="footer" className="text-gray-600 body-font">
          <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
              <a href="/#" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                  <img className="h-12" alt="octopus" src={bgImg}/>
              </a>
              <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© {currentYear()} Chobotnička Baby Club —
                  <a href="/#" className="text-gray-600 ml-1" rel="noopener noreferrer"
                     target="_blank">@davidko</a>
              </p>

              <a href={Pdf} without rel="noopener noreferrer" target="_blank">
                  <button className="uppercase text-xs text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4" trailingIcon="picture_as_pdf" label="Resume">
                      <PictureAsPdfIcon/> Provozní řád
                  </button>
              </a>
              <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a className="text-gray-500 hover:text-blue-700 dark:hover:text-white" href="http://facebook.com/plavaniprodeti">
        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5"
             viewBox="0 0 24 24">
          <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
        </svg>
      </a>

    </span>
          </div>
      </footer>
  )
}


export default Footer