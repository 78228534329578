import {useState, useEffect} from "react";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import Hamburger from 'hamburger-react'

const Navbar = () => {

    const [nav, setNav] = useState(false);
    const [theme, setTheme] = useState("light")
    const [darkModeStatus, setDarkModeStatus] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [top, setTop] = useState(true);


    useEffect(() => {
        if(theme === "dark")
        {
            document.documentElement.classList.add("dark")
            localStorage.setItem("theme", theme)
        }
        else
        {
            document.documentElement.classList.remove("dark")
            localStorage.setItem("theme", theme)
        }

    },[theme])

    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);


    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark")
        setDarkModeStatus(!darkModeStatus)
    }

    const handleMenu = () => {
        setNav(!nav)
        setOpen(!isOpen)
    }

  return (
    <div className={`w-screen h-[85px] z-10 bg-transparent fixed  ${!top && `bg-gradient-to-b top-0 from-fuchsia-200 to-fuchsia-200 drop-shadow-lg dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-900`}`}>
        <div className='px-2 flex justify-between items-center w-full h-full'>
            <div className='flex items-center'>
                <h1 className='text-3xl font-pacifico mr-4 mt-2 sm:text-4xl text-fuchsia-800 dark:text-white cursor-default'>Chobotnička</h1>
                <p className='text-[9px] font-pacifico absolute text-black mt-14 ml-28 sm:text-[15px] dark:text-fuchsia-400 cursor-default'>Baby Club</p>
                <ul className='xl:text-[16px] md:text-[12px] hidden md:flex mt-4 ml-7 font-ysabeau font-bold uppercase text-black cursor-default'>
                    <a href="#home"><li>Domů</li></a>
                    <a href="#about"><li>O nás</li></a>
                    <a href="#price"><li>Programy</li></a>
                    <a href="#place"><li>Kde plaveme</li></a>
                    <a href="#contact"><li>Kontakt</li></a>
                </ul>
            </div>
            <div className='hidden md:flex pr-8 pt-3'>
                <DarkModeSwitch
                    checked={darkModeStatus}
                    onChange={handleThemeSwitch}
                    size={24}
                />

            </div>
            <div className='text-black md:hidden dark:text-white mr-4' onClick={() => setNav(!nav)}>
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
        </div>

        <ul className={!nav ? 'hidden' : 'absolute bg-zinc-100 pb-6 w-full px-8 text-center text-black uppercase dark:bg-gray-900'} onClick={handleMenu}>
            <a href="#home"><li className='border-b border-zinc-300 w-full mt-2'>Domů</li></a>
            <a href="#about"><li className='border-b border-zinc-300 w-full mt-2'>O nás</li></a>
            <a href="#price"><li className='border-b border-zinc-300 w-full mt-2'>Programy</li></a>
            <a href="#place"><li className='border-b border-zinc-300 w-full mt-2'>Kde plaveme</li></a>
            <a href="#contact"><li className='border-b border-zinc-300 w-full mt-2 mb-4'>Kontakt</li></a>
            <li className="flex items-center justify-center">
                <DarkModeSwitch
                checked={darkModeStatus}
                onChange={handleThemeSwitch}
                size={24}/>
            </li>
        </ul>

    </div>
  )
}


export default Navbar