import vss from "../Assets/vss.jpeg"
import adLandek from "../Assets/adlandek.jpeg"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';

const Place = () => {
  return (
      <section id="place" className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
              <div className="flex flex-wrap w-full mb-20 pt-6">
                  <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 dark:text-stone-50">Areály, kde plaveme...</h1>
                      <div className="h-1 w-40 bg-fuchsia-800 rounded dark:bg-fuchsia-500"></div>
                  </div>
                  <p className="lg:w-1/2 w-full leading-relaxed text-gray-500 italic dark:text-stone-200">„Pro Vaničkové plavání probíhají lekce u Vás doma po telefonické domluvě. Individální lekce po dohodě na ostravských bazénech. Pro více informaci nás prosím kontaktujte.“</p>
              </div>

              <div className="font-light grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div id="accordionPlaceExample">
                      <div className="md:mb-0 mb-4 w-full rounded-t-lg border border-neutral-200 bg-white dark:bg-gray-900 dark:border-gray-900 mr-8 cursor-pointer">
                          <h2 className="mb-0" id="headingPlaceOne">
                              <div className="group relative flex flex-col w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target="#collapsePlaceOne" aria-expanded="false" aria-controls="collapsePlaceOne">
                                  <img className="h-40 rounded shadow-md w-full object-cover object-center mb-6"
                                       src={vss} alt="content">
                                  </img>
                                  <h3 className="tracking-widest text-fuchsia-600 text-xl font-medium title-font text-center dark:text-fuchsia-500">VODNÍ SVĚT!!! SAREZA</h3>
                                  <h2 className="text-sm text-gray-900 font-medium title-font mb-4 text-center dark:text-stone-50 mt-2">Sokolská třída 44/2590<br/>702 00 Ostrava - Moravská Ostrava</h2>
                              </div>
                          </h2>
                          <div id="collapsePlaceOne" className="!visible hidden" data-te-collapse-item aria-labelledby="headingPlaceOne" data-te-parent="#accordionPlaceExample">
                              <div className="px-5 py-4 text-black dark:text-stone-50">
                                  <p className="text-center"><a href="https://www.sareza.cz/vodni-svet/"><LanguageIcon className="m-4 hover:text-pink-700 cursor-pointer dark:text-white dark:hover:text-pink-700"/></a><a href="https://www.facebook.com/VodniSvetSareza/"><FacebookIcon className="m-4 hover:text-blue-700 cursor-pointer dark:text-white dark:hover:text-blue-700"/></a></p>
                                  <ul className="list-none text-black text-sm">
                                      <li><ChevronRightIcon/>Plavání probíhá ve  Vodním světě!!! Sareza, v prostorách vodoléčby </li>
                                      <li><ChevronRightIcon/>Lekce trvá 30 minut + pobyt v odpočívárně/herně </li>
                                      <li><ChevronRightIcon/>Po plavání se maminky mohou s dětmi zadaptovat v herně, zároveň se občerstvit před odchodem domů</li>
                                      <li><ChevronRightIcon/>Procvičování základních plaveckých dovedností (splývání, orientaci ve vodě, dýchání do vody) hravou formou s využitím plaveckých pomůcek </li>
                                      <li><ChevronRightIcon/>Pobyt  dětí ve vodě má stimulující efekt na zvýšení koncentrace, pozornosti, vnímací schopnosti a rozvoj motorických dovedností, zlepšení sociálního, citového a fyzického vývoje</li>
                                      <li><ChevronRightIcon/>Teplota vody 30 - 32 °C</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div id="accordionPlaceExample2">
                      <div className="w-full rounded-t-lg border border-neutral-200 bg-white dark:bg-gray-900 dark:border-gray-900 cursor-pointer">
                          <h2 className="mb-0" id="headingPlaceTwo">
                              <div className="group relative flex flex-col w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-gray-900 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-fuchsia-800 [&:not([data-te-collapse-collapsed])]:font-bold [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-fuchsia-500 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target="#collapsePlaceTwo" aria-expanded="false" aria-controls="collapsePlaceTwo">
                                  <img className="h-40 rounded w-full shadow-md object-cover object-center mb-6"
                                       src={adLandek} alt="content"/>
                                  <h3 className="tracking-widest text-fuchsia-600 text-xl font-medium title-font text-center dark:text-fuchsia-500">Apartmánový dům LANDEK</h3>
                                  <h2 className="text-sm text-gray-900 font-medium title-font mb-4 text-center dark:text-stone-50 mt-2">U Nemocnice 837/2<br/>725 29 Ostrava-Petřkovice</h2>
                              </div>
                          </h2>
                          <div id="collapsePlaceTwo" className="!visible hidden" data-te-collapse-item aria-labelledby="headingPlaceTwo" data-te-parent="#accordionPlaceExample2">
                              <div className="px-5 py-4 text-black dark:text-stone-50">
                                  <ul className="list-none text-black text-sm">
                                      <p className="text-center"><a href="https://www.hotel-landek.cz/wellness/"><LanguageIcon className="m-4 hover:text-pink-700 cursor-pointer dark:text-white dark:hover:text-pink-700"/></a><a href="https://www.facebook.com/adlandek/"><FacebookIcon className="m-4 hover:text-blue-700 cursor-pointer dark:text-white dark:hover:text-blue-700"/></a></p>
                                      <li><ChevronRightIcon/>V areálu AD Landek v Petřkovicích</li>
                                      <li><ChevronRightIcon/>Lekce trvá 30 nebo 45 minut (podle věku a dovedností dítěte) + 10 minut pára</li>
                                      <li><ChevronRightIcon/>Lekce pro školní a pokročilé trvá 45 minut + 10 minut pára</li>
                                      <li><ChevronRightIcon/>Plavání dětí s rodiči i bez rodičů (dle zdatnosti dětí cca od 5 let bez rodičů)</li>
                                      <li><ChevronRightIcon/>Zdokonalování základních plaveckých dovedností</li>
                                      <li><ChevronRightIcon/>Výuka plaveckých způsobů prsa, kraul, znak, ploutvové plavání, soutěže a hry</li>
                                      <li><ChevronRightIcon/>Teplota vody 30 °C</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
              </div>





              </div>
          </div>
      </section>
  )
}


export default Place