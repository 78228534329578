import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const Alert = (props) => {

    let content = null;

    if (props.type === "Warning")
    {
        content = <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-2" role="alert">
            <p className="font-bold text-md"><WarningIcon className="mr-2"/>Upozornění<span className="font-thin text-xs sm:text-sm"> - {props.msg}</span></p>
                 </div>
    }
    else if (props.type === "Info")
    {
        content = <div className="bg-blue-300 border-l-4 border-blue-500 text-blue-700 p-2" role="alert">
            <p className="font-bold text-md"><InfoIcon className="mr-2"/>Informace<span className="font-thin text-xs sm:text-sm"> - {props.msg}</span></p>
        </div>
    }

  return (

      <div>
          {content}
      </div>

  )
}


export default Alert